import axios from 'axios'

const API_URL = 'https://new.backinthegame.gr/api/web'
const headers = {
    'Content-Type': 'application/json; charset=utf-8'
}
const formDataHeaders = {
    'Content-Type': 'multipart/form-data'
}
export default {
    post(urlPath, data) {
        return this.request(urlPath, 'POST', headers, data)
    },
    put(urlPath, data) {
        return this.request(urlPath, 'PUT', headers, data)
    },
    get(urlPath) {
        return this.request(urlPath, 'GET', headers, null)
    },
    delete(urlPath) {
        return this.request(urlPath, 'DELETE', headers, null)
    },
    getFile(urlPath, fileName) {
        window.open(API_URL + urlPath, '_blank')
    },
    uploadFile(urlPath, formData) {
        return this.request(urlPath, 'POST', formDataHeaders, formData)
    },
    request(urlPath, method, headers, data) {
        data = data || {}
        headers = headers || {}

        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + '/' + urlPath,
                data: data,
                method: method,
                headers: headers
            }).then(function(response) {
                const contetType = response.headers['content-type']
                if (contetType && contetType.indexOf('application/json') > -1) {
                    return response
                } else {
                    resolve()
                }
            }).then((data) => {
                resolve(data)
            }).catch((error) => {
                if (!error.response) {
                    // eventBus.$emit('sessionExpired')
                }
                reject(error)
            })
        })
    }
}
